<template>
	<div id="navPopup">
		<div id="topCont">
			<NavGutsMobile />
		</div>
		<div
			id="navLinkCont"
			@click="
				mainStore.toggleNav();
				smoothScrollTop();
			"
		>
			<router-link
				v-for="link in links"
				class="mainLink"
				:class="{ moveLinks: mainStore.getState().navIsOpen }"
				:style="link.delay"
				:to="link.to"
				:key="link.title"
			>
				{{ link.title }}
			</router-link>
		</div>
		<div class="theme-button-container">
			<ThemeToggleButton />
		</div>
	</div>
</template>

<script>
// Dependencies
import { ref } from 'vue';
import { smoothScrollTop } from '@/plugins/utilities';

// Component Imports
import NavGutsMobile from '@/components/navigation/NavGutsMobile.vue';
import ThemeToggleButton from '@/components/clickables/ThemeToggleButton.vue';
import { mainStore } from '@/store/main';

export default {
	name: 'NavPopup',
	components: {
		NavGutsMobile,
		ThemeToggleButton,
	},

	setup() {
		return {
			mainStore,
			links,
			smoothScrollTop,
		};
	},
};

const links = ref([
	{
		title: 'Home',
		to: { name: 'HomePage' },
		delay: 'transition-delay: 0ms;',
	},
	{
		title: 'About',
		to: { name: 'About' },
		delay: 'transition-delay: 10ms;',
	},
	{
		title: 'Portfolio',
		to: { name: 'Portfolio' },
		delay: 'transition-delay: 20ms;',
	},
	{
		title: 'Contact',
		to: { name: 'Contact' },
		delay: 'transition-delay: 40ms;',
	},
]);
</script>

<style scoped>
#navPopup {
	width: 100%;
	height: 100%;
	background: rgb(0, 234, 255);
	background: linear-gradient(
		135deg,
		rgba(0, 204, 255, 1) 0%,
		rgba(190, 0, 255, 1) 60%,
		rgba(255, 117, 0, 1) 100%
	);
	background-size: 200%;
	animation: gradientMove 14s infinite ease-in-out;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

@keyframes gradientMove {
	0% {
		background-position: 0%;
	}
	50% {
		background-position: 100%;
	}
	100% {
		background-position: 0%;
	}
}

#topCont {
	border: solid yellow 0px;
	z-index: 16;
	width: 100%;
	height: 64px;
}

#navLinkCont {
	width: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.mainLink {
	font-size: 18px;
	color: rgb(255, 255, 255);
	text-decoration: none;
	width: 90px;
	border: solid red 0px;
	text-align: center;
	opacity: 0.85;
	transition: font-weight cubic-bezier(0.74, -0.11, 0.48, 1.23) 150ms 0ms,
		opacity cubic-bezier(0.74, -0.11, 0.48, 1.23) 300ms 0ms,
		transform ease-in-out 600ms;
	margin: 14px 0px 14px 0px;
	transform: translateY(100px);
}

a.router-link-active {
	font-weight: bold;
	opacity: 1;
}

.moveLinks {
	transform: translateY(0px);
}

.theme-button-container {
	margin: 50px 0px 50px 0px;
}

@keyframes gradientMove {
	0% {
		background-position: 0%;
	}
	50% {
		background-position: 100%;
	}
	100% {
		background-position: 0%;
	}
}
</style>
