import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
// import NotFound from "../views/NotFound.vue";

const routes = [
	{
		path: '/',
		name: 'HomePage',
		component: HomePage,
	},
	//Lazy-loaded chunk example:
	// {
	//   path: "/about",
	//   name: "About",
	//   // route level code-splitting
	//   // this generates a separate chunk (about.[hash].js) for this route
	//   // which is lazy-loaded when the route is visited.
	//   component: () =>
	//     import(/* webpackChunkName: "about" */ "../views/About.vue"),
	// },
	{
		path: '/about',
		name: 'About',
		component: () => import('../views/About.vue'),
	},
	{
		path: '/portfolio',
		name: 'Portfolio',
		component: () => import('../views/Portfolio.vue'),
	},
	{
		path: '/services',
		name: 'Services',
		component: () => import('../views/Services.vue'),
	},
	{
		path: '/contact',
		name: 'Contact',
		component: () => import('../views/Contact.vue'),
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/Login.vue'),
	},
	{
		path: '/admin-panel',
		name: 'AdminPanel',
		component: () => import('../views/AdminPanel.vue'),
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		component: () => import('../views/NotFound.vue'),
	},
	// Portfolio Routes
	{
		path: '/portfolio/nsm-intranet',
		name: 'Portfolio: NSM Intranet',
		component: () => import('../views/portfolioPages/PortNSMInt.vue'),
	},
	{
		path: '/portfolio/berg-enterprises',
		name: 'Portfolio: Berg Enterprises',
		component: () => import('../views/portfolioPages/PortBergEnterprises.vue'),
	},
	{
		path: '/portfolio/bitfluent',
		name: 'Portfolio: Bitfluent',
		component: () => import('../views/portfolioPages/PortBitfluent.vue'),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
