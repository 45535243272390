<template>
	<div class="loading">
		<div class="loading-bar"></div>
	</div>
</template>

<script>
// Dependency Imports
import {} from 'vue';

// Component Imports

// -=-=-=-=-=-=-=-=-=-=-=-

export default {
	name: 'Loading',
	components: {},

	setup() {
		return {};
	},
};
</script>

<style scoped>
.loading {
	background-color: var(--body-bg-color);
	color: white;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 100;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.loading-bar {
	width: 220px;
	height: 4px;
	background: #cfcfcf;
	margin-top: 22px;
	position: relative;
	overflow: hidden;
	border-radius: 2px;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.loading-bar::before {
	content: '';
	width: 130px;
	height: 4px;
	background: linear-gradient(
		90deg,
		rgba(0, 204, 255, 1) 0%,
		rgba(190, 0, 255, 1) 200%
	);
	position: absolute;
	left: -100px;
	border-radius: 2px;
	animation: bluebar 1.5s infinite ease;
}

img {
	width: 280px;
}

@keyframes bluebar {
	50% {
		left: 190px;
	}
}
</style>
