<template>
	<div id="hero">
		<div class="hero-background" :class="{ 'bg-animated': !lowAnimations }"></div>
	</div>
</template>

<script>
// Dependencies
import { computed } from 'vue';
import { mainStore } from '@/store/main';

// Components import

export default {
	name: 'Hero',
	components: {},

	setup() {
		// Injections

		// Setup Returns
		return { isMobile, lowAnimations };
	},
};

const isMobile = computed(() => {
	return mainStore.getState().isMobile;
});

const lowAnimations = computed(() => {
	return mainStore.getState().lowAnimations;
});
</script>

<style scoped>
#hero {
	z-index: 0;
	width: 100%;
	height: 500px;
	position: absolute;
	border: solid blue 0px;
}

.hero-background {
	width: 100%;
	height: 600px;
	background: rgb(0, 234, 255);
	background: var(--hero-gradient);
	background-size: 200%;
	mask: url('../assets/heroWave.svg');
	mask-size: 1600px 600px;
	mask-clip: border-box;
	mask-repeat: repeat-x;
}

.bg-animated {
	animation: waveMove 12s infinite linear, gradientMove 22s infinite ease-in-out;
}

.blurred {
	filter: blur(30px);
	border: solid green 0px;
	height: 1000px;
	opacity: 0.4;
	z-index: -1;
}

@keyframes waveMove {
	0% {
		mask-position: 0px 0px;
	}
	100% {
		mask-position: -1600px 0px;
	}
}

@keyframes gradientMove {
	0% {
		background-position: 0%;
	}
	50% {
		background-position: 100%;
	}
	100% {
		background-position: 0%;
	}
}

.navigation {
	margin: 15px 0px 20px 0px;
}
</style>
