<template>
	<div id="home">
		<section id="heroContent">
			<div id="heroText">
				<h1 class="main1">Web Potential:</h1>
				<h1 class="main2">Unleashed</h1>
				<h3 class="heroHeadline">
					We design and develop quality single-page web applications, tailored to
					your businesses.
				</h3>
			</div>
			<div id="heroImgCont">
				<img src="@/assets/designerHero.svg" id="heroImg" />
			</div>
		</section>
		<section id="cards">
			<IconCard
				v-for="card in iconCards"
				:key="card.title"
				:cardTitle="card.title"
				:icons="card.icons"
			/>
		</section>
		<section id="bulletPoints">
			<BulletCard
				imgName="coding.svg"
				title="Not Just a Website"
				paragraph="We don't just build websites, we design and develop
			custom, app-like experiences and web solutions that enhance your business. There's no cookie-cutter action here; we take a personalized approach to designing around your unique business qualities."
				animDelay="0ms"
			/>
			<!-- <img src="../assets/topSwoop.svg" class="topSwoop" /> -->
			<BulletCard
				imgName="world.svg"
				title="Why the Web?"
				paragraph="It's instantly accessible, cross-platform, and has a mature toolset. Developers like us can now create app-like experiences without users needing to leave the page or download and install an application. The web isn't going anywhere either; it's here to stay and the future of the platform has never looked brighter!"
				animDelay="2000ms"
			/>

			<BulletCard
				imgName="people.svg"
				title="Built for People"
				paragraph="The web applications we design and develop are built with the end user in mind every step of the way. This primary focus leads us to create amazing web experiences that seek to serve the user above all else."
				animDelay="4000ms"
			/>
		</section>
		<section id="findOutMore">
			<div @click="smoothScrollTop()">
				<router-link class="continue moreButton" :to="{ name: 'About' }"
					>More About Bitfluent</router-link
				>
			</div>
			<div @click="smoothScrollTop()">
				<router-link class="continue portButton" :to="{ name: 'Portfolio' }"
					>See Our Work</router-link
				>
			</div>
		</section>
	</div>
</template>

<script>
// Dependency Imports
import { ref } from 'vue';
import { smoothScrollTop } from '@/plugins/utilities';

// Component Imports
import IconCard from '@/components/cards/IconCard.vue';
import BulletCard from '@/components/cards/BulletCard.vue';

export default {
	name: 'Home',
	components: {
		IconCard,
		BulletCard,
	},

	setup() {
		// Injections

		// Setup Returns
		return {
			iconCards,
			smoothScrollTop,
		};
	},
};

// Data for Icon Cards
const iconCards = ref([
	{
		title: 'Powerful Technologies',
		icons: [
			{ alt: 'Vue JS', path: require('@/assets/cardIcons/vuejs.svg') },
			{ alt: 'Node JS', path: require('@/assets/cardIcons/nodejs.svg') },
			{ alt: 'MySQL', path: require('@/assets/cardIcons/mysql.svg') },
			{
				alt: 'ES6+',
				path: require('@/assets/cardIcons/javascript.svg'),
			},
			{ alt: 'CSS 3+', path: require('@/assets/cardIcons/css3.svg') },
			{ alt: 'HTML 5+', path: require('@/assets/cardIcons/html5.svg') },
		],
	},
	{
		title: 'Cross-Platform',
		icons: [
			{ alt: 'Safari', path: require('@/assets/cardIcons/safari.svg') },
			{ alt: 'Firefox', path: require('@/assets/cardIcons/firefox.svg') },
			{ alt: 'Chrome', path: require('@/assets/cardIcons/chrome.svg') },
			{ alt: 'Edge', path: require('@/assets/cardIcons/edge.svg') },
			{ alt: 'Desktop', path: require('@/assets/cardIcons/desktop.svg') },
			{ alt: 'Mobile', path: require('@/assets/cardIcons/mobile.svg') },
		],
	},
	{
		title: 'UI/UX & Full Stack',
		icons: [
			{ alt: 'Design', path: require('@/assets/cardIcons/application.svg') },
			{ alt: 'Develop', path: require('@/assets/cardIcons/code.svg') },
			{ alt: 'Client', path: require('@/assets/cardIcons/browser.svg') },
			{ alt: 'Server', path: require('@/assets/cardIcons/server.svg') },
		],
	},
]);
</script>

<style scoped>
#home {
	border: solid yellow 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
	/* z-index: 10; */
}
#heroContent {
	height: auto;
	width: 100%;
	margin: 0px;
	position: relative;
	z-index: 0;
	border: solid green 0px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	z-index: 0;
}
#heroText {
	display: flex;
	flex-direction: column;
	justify-content: left;
	align-items: flex-start;
	border: solid green 0px;
	padding: 0px 0px 0px 40px;
	margin: 30px 0px 30px 0px;
	border: solid blue 0px;
	max-width: 510px;
	width: 100%;
}

#cards {
	margin: 60px 0px 0px 0px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

#bulletPoints {
	border: solid blue 0px;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0px 0px 0px 0px;
}

#findOutMore {
	width: 100%;
	height: auto;
	min-height: 150px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	padding: 100px 0px 50px 0px;
}

.continue {
	width: 280px;
	height: 60px;
	font-size: 18px;
	font-weight: bold;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	border-radius: 60px;
	margin: 20px;
	color: rgba(255, 255, 255, 0.9);
	background: linear-gradient(
		145deg,
		rgba(0, 172, 255, 1) 0%,
		rgba(179, 0, 255, 1) 100%
	);
	background-size: 200%;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
	transition: cubic-bezier(0.56, 0, 0.39, 2.5) 230ms;
}

.continue:hover {
	transform: translateY(-3px);
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
}

.continue:active {
	transform: translateY(2px);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

.moreButton {
	background-position: 0px 0px;
}

.portButton {
	background-position: 300px;
}

h1 {
	color: rgba(255, 255, 255, 0.92);
	text-shadow: rgba(0, 0, 0, 0.1) 0px 4px 26px;
	margin: -10px 0px -10px 0px;
	border: solid yellow 0px;
}

h1.main1 {
	font-size: clamp(10px, 11vw, 60px);
}

h1.main2 {
	font-size: clamp(20px, 16vw, 90px);
}

h3.heroHeadline {
	font-size: clamp(8px, 6vw, 24px);
	color: rgba(255, 255, 255, 0.92);
	text-shadow: rgba(0, 0, 0, 0.1) 0px 2px 18px;
	border: solid yellow 0px;
	width: 90%;
	max-width: 500px;
	text-align: left;
	font-weight: 500;
	margin: 10px 0px 0px 0px;
}

#heroImg {
	width: 100vw;
	max-width: 600px;
	height: auto;
	margin: 0px -20px 0px -20px;
	border: solid blue 0px;
	animation: heroImageBounce 10000ms ease-in-out infinite;
}

#heroImgCont {
	border: solid yellow 0px;
}

/* Animations */

@keyframes heroImageBounce {
	0% {
		transform: translateY(-4px) rotate(0.5deg);
	}
	50% {
		transform: translateY(4px) rotate(-0.5deg);
	}
	100% {
		transform: translateY(-4px) rotate(0.5deg);
	}
}

@media only screen and (max-width: 800px) {
}
</style>
