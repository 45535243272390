<template>
	<div class="bulletCard">
		<div class="textCont">
			<h3>{{ title }}</h3>
			<p>{{ paragraph }}</p>
		</div>
		<img
			:src="require(`@/assets/${imgName}`)"
			:style="{ 'animation-delay': animDelay }"
		/>
	</div>
</template>

<script>
// Dependency Imports
import {} from 'vue';

//Component Imports

export default {
	name: 'BulletCard',
	components: {},
	props: {
		imgName: String,
		title: String,
		paragraph: String,
		animDelay: String,
	},

	setup() {
		return {};
	},
};
</script>

<style scoped>
.bulletCard {
	width: 100%;
	border: solid blue 0px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin: 60px 0px 0px 0px;
}

.bulletCard:nth-child(2) {
	flex-direction: row-reverse;
}

.textCont {
	max-width: 400px;
	border: solid red 0px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	margin: 20px 40px 20px 40px;
	color: var(--text-color);
}

img {
	max-width: 460px;
	width: 100%;
	margin: 0px 40px 0px 40px;
	animation: heroImageBounce 10000ms ease-in-out infinite;
}

@keyframes heroImageBounce {
	0% {
		transform: translateY(0px) rotate(0deg);
	}
	25% {
		transform: translateY(-4px) rotate(-0.5deg);
	}
	50% {
		transform: translateY(0px) rotate(0deg);
	}
	75% {
		transform: translateY(4px) rotate(0.5deg);
	}
	100% {
		transform: translateY(0px) rotate(0deg);
	}
}

h3 {
	font-size: clamp(20px, 16vw, 42px);
	background: linear-gradient(
		145deg,
		rgba(18, 178, 255, 1) 0%,
		rgba(197, 58, 255, 1) 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	text-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px;
	margin: 0px 0px 10px 0px;
}

p {
	text-align: left;
	font-size: 18px;
}
</style>
