<template>
	<div id="app" :style="activePalette">
		<!-- Off-page material  -->
		<transition name="load">
			<Loading v-if="isLoading" />
		</transition>
		<StickyNav />

		<transition name="fade">
			<div id="navPopCont" v-show="navIsOpen">
				<NavPopup />
			</div>
		</transition>

		<!-- Main Content -->
		<div id="siteContent">
			<Hero />
			<div id="heroNavCont">
				<NavGutsMobile v-if="isMobile" />
				<NavGutsDesktop v-else />
			</div>
			<router-view v-slot="{ Component }">
				<transition name="routeFade" mode="out-in">
					<component :is="Component" />
				</transition>
			</router-view>
		</div>
		<Footer />
	</div>
</template>

<script>
// Dependencies
import { computed, onBeforeMount, ref } from 'vue';
import { mainStore } from '@/store/main';

// Component Imports
import NavGutsDesktop from '@/components/navigation/NavGutsDesktop.vue';
import NavGutsMobile from '@/components/navigation/NavGutsMobile.vue';
import StickyNav from '@/components/navigation/StickyNav.vue';
import NavPopup from '@/components/navigation/NavPopup.vue';
import Hero from '@/components/Hero.vue';
import Footer from '@/components/Footer.vue';
import Loading from '@/components/Loading.vue';

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

export default {
	name: 'app',
	components: {
		StickyNav,
		NavGutsDesktop,
		NavGutsMobile,
		Hero,
		NavPopup,
		Footer,
		Loading,
	},

	setup() {
		const isLoading = ref(true);
		window.onload = () => {
			isLoading.value = false;
		};

		// Initialize App
		onBeforeMount(() => {
			// Check if client is an iPhone (header animations freak out on it). Wave animation disables if true.
			mainStore.setMobileSafari();

			// Communicates platform type based on window width to state.
			let displayWidthMedia = window.matchMedia('(max-width: 800px)');

			if (displayWidthMedia.matches) {
				mainStore.setMobile(true);
			}
			displayWidthMedia.addEventListener('change', () => {
				if (displayWidthMedia.matches) {
					mainStore.setMobile(true);
				} else {
					mainStore.setMobile(false);
				}
			});

			// Communicate theme changes to state.
			let darkMedia = window.matchMedia('(prefers-color-scheme: dark)');

			if (darkMedia.matches) {
				mainStore.setDarkMode(true);
			}
			darkMedia.addEventListener('change', (e) => {
				if (e.matches) {
					mainStore.setDarkMode(true);
				} else {
					mainStore.setDarkMode(false);
				}
			});

			// Get scroll position and communicate stickyNav switch to state.
			let stickyThreshold = -520;
			window.addEventListener('scroll', () => {
				if (
					document.body.getBoundingClientRect().top < stickyThreshold &&
					!mainStore.getState().stickyNavIsOpen
				) {
					mainStore.setStickyNavOpen(true);
				} else if (
					document.body.getBoundingClientRect().top > stickyThreshold &&
					mainStore.getState().stickyNavIsOpen
				) {
					mainStore.setStickyNavOpen(false);
				}
			});
		});

		// Component returns
		return {
			isLoading,
			mainStore,
			activePalette: computed(() => {
				if (mainStore.getState().themeIsDark) {
					return colorPalette.dark;
				} else {
					return colorPalette.light;
				}
			}),
			isMobile: computed(() => {
				return mainStore.getState().isMobile;
			}),
			navIsOpen: computed(() => {
				return mainStore.getState().navIsOpen;
			}),
		};
	},
};

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

// Define Light and Dark mode Styles. Bound to app element.
const colorPalette = {
	light: {
		'--body-bg-color': 'rgba(251, 250, 251, 1)',
		'--body-bg-gradient': `linear-gradient(
			180deg,
			rgba(206, 179, 249, 1) 0%,
			rgba(249, 245, 255, 1) 900px
		)`,
		'--hero-gradient': `linear-gradient(
			135deg,
			rgba(0, 204, 255, 1) 0%,
			rgba(190, 0, 255, 1) 60%,
			rgba(255, 117, 0, 1) 100%
		)`,
		'--footer-gradient': `linear-gradient(
			180deg,
			rgba(249, 245, 255, 0) 0%,
			rgba(230, 220, 250, 1) 100%
		)`,
		'--card-gradient': `linear-gradient(
			180deg,
			rgba(255, 255, 255, 0.5) 0%,
			rgba(254, 252, 255, 0.5) 60%,
			rgba(253, 250, 255, 0) 100%
		)`,
		'--card-gradient-contrast': `linear-gradient(
			180deg,
			rgba(255, 255, 255, 0.90) 0%,
			rgba(254, 252, 255, 0.7) 80%,
			rgba(253, 250, 255, 0) 90%
		)`,
		'--card-shadow': '0px -20px 20px rgba(0, 0, 0, 0.02)',
		'--card-header-shadow': 'rgba(0, 0, 0, 0.08) 0px 2px 3px',
		'--text-color': 'rgba(0, 0, 0, 0.7)',
		'--text-shadow': 'rgba(0, 0, 0, 0.1) 0px 2px 3px',
	},
	dark: {
		'--body-bg-color': 'rgba(1, 14, 39, 1)',
		'--body-bg-gradient': `linear-gradient(
			180deg,
			rgba(102,36,140,1) 0%, rgba(1, 14, 39,1) 900px
		)`,
		'--hero-gradient': `linear-gradient(
			135deg,
			rgba(0,168,210, 1) 0%,
			rgba(149,0,201, 1)  60%,
			rgba(212,98,0, 1) 100%
		)`,
		'--footer-gradient': `linear-gradient(
			180deg,
			rgba(1, 14, 39,0) 0%, rgba(25,20,61,1) 100%
		)`,
		'--card-gradient': `linear-gradient(
			180deg,
			rgba(0, 0, 0, 0.3) 0%,
			rgba(0, 0, 0, 0.3) 40%,
			rgba(0, 0, 0, 0) 100%
		)`,
		'--card-gradient-contrast': `linear-gradient(
			180deg,
			rgba(1, 14, 39, .8) 0%,
			rgba(1, 14, 39, .6) 80%,
			rgba(1, 14, 39, 0) 90%
		)`,
		'--card-shadow': '0px -20px 20px rgba(255, 255, 255, .015)',
		'--card-header-shadow': 'rgba(255, 255, 255, 0.1) 0px 2px 3px',
		'--text-color': 'rgba(255, 255, 255, 0.8)',
		'--text-shadow': 'rgba(255, 255, 255, 0.2) 0px 2px 3px',
	},
};
</script>

<style>
#app {
	width: 100%;
	min-height: 100vh;
	max-width: 100%;
	/* overflow-x: hidden; */
	height: auto;
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	background-color: var(--body-bg-color);
	background: var(--body-bg-gradient);
	display: flex;
	flex-direction: column;
}

#siteContent {
	min-height: 100%;
	overflow: hidden;
	flex-grow: 1;
}

#heroNavCont {
	border: solid yellow 0px;
	z-index: 5;
	width: 100%;
	height: 100px;
}

#navPopCont {
	width: 100%;
	height: 100%;
	position: fixed;
	display: hidden;
	z-index: 100;
}

#footerCont {
	margin: 30px 0px 0px 0px;
	border: solid green 1px;
	position: relative;
}

.fade-enter-active,
.fade-leave-active {
	transition: all 400ms cubic-bezier(0.47, 0, 0.2, 1);
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.routeFade-enter-active,
.routeFade-leave-active {
	transition: all 300ms ease-in-out, transform 300ms ease-in-out;
}

.routeFade-enter-from,
.routeFade-leave-to {
	opacity: 0;
	transform: translateY(4px);
}

.load-enter-active {
	transition: all 0ms linear;
}
.load-leave-active {
	transition: all 500ms ease;
}

.load-enter-from {
	opacity: 1;
}
.load-leave-to {
	opacity: 0;
	transform: scale(1.1);
}

/* Styles for 800px width devices */

@media only screen and (max-width: 800px) {
	#heroNavCont {
		height: 64px;
	}
}
</style>
