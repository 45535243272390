import { readonly, reactive } from 'vue';
import { api } from '@/plugins/wire-config.js';

// Primary Store Parent Class
export default class Store {
	constructor() {
		let data = this.data();
		this.state = reactive(data);
	}
	getState() {
		return readonly(this.state);
	}
}

// -=-=-=-=-=-=- Main application store -=-=-=-=-=-=-=-
class MainStore extends Store {
	data() {
		return {
			themeIsDark: false,
			navIsOpen: false,
			stickyNavIsOpen: false,
			isMobile: false,
			lowAnimations: false,
			session: {
				username: '',
				firstName: '',
				lastName: '',
				isAuth: false,
				privileges: [],
			},
		};
	}

	// Mutations
	setDarkMode(payload) {
		if (typeof payload !== 'boolean') {
			console.error(`Payload for theme change must be 'dark' or 'light.'`);
		} else {
			this.state.themeIsDark = payload;
		}
	}
	setMobile(payload) {
		if (typeof payload == 'boolean') {
			this.state.isMobile = payload;
		} else {
			console.error('setMobile() payload must be a boolean.');
		}
	}
	toggleNav() {
		this.state.navIsOpen = this.state.navIsOpen ? false : true;
	}
	setStickyNavOpen(payload) {
		if (typeof payload == 'boolean') {
			this.state.stickyNavIsOpen = payload;
		} else {
			console.error('toggleStickyNav() payload must be a boolean.');
		}
	}
	async updateSessionData() {
		let sessionData = await api.get('/session', (err) => {
			console.error(err);
		});
		this.state.session = sessionData;
	}

	// GPU intensive animations are reduced when firefox or mobile safari.
	setMobileSafari() {
		let ua = window.navigator.userAgent;
		// let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
		let iOS = !!ua.match(/iPhone/i);
		let webkit = !!ua.match(/WebKit/i);
		let firefox = !!ua.match(/Firefox/i);
		let iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

		this.state.lowAnimations = iOSSafari || firefox ? true : false;
	}
}

export const mainStore = new MainStore();
