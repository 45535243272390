<template>
	<div id="cardCont" @mouseover="hovering = true" @mouseleave="hovering = false">
		<FadeCard>
			<h3>{{ cardTitle }}</h3>
			<div class="iconCont">
				<div class="pair" v-for="icon in icons" :key="icon.alt">
					<div :class="{ 'hover-fade': hovering }" class="iconText">
						{{ icon.alt }}
					</div>
					<img :src="icon.path" />
				</div>
			</div>
		</FadeCard>
	</div>
</template>

<script>
// Dependency Imports
import { ref } from 'vue';

//Component Imports
import FadeCard from '@/components/cards/FadeCard.vue';

export default {
	name: 'IconCard',
	components: {
		FadeCard,
	},

	props: {
		cardTitle: String,
		icons: Array,
	},

	setup() {
		return {
			hovering: ref(false),
		};
	},
};
</script>

<style scoped>
#cardCont {
	width: 100%;
	max-width: 360px;
	min-height: 340px;
	height: auto;
	margin: 0px 40px 50px 40px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

div.iconCont {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

div.pair {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.iconText {
	text-align: center;
	opacity: 0;
	transition: all 200ms cubic-bezier(0.47, 0, 0.2, 1);
	transform: translateY(15px);
	text-shadow: var(--text-shadow);
	color: var(--text-color);
}

.hover-fade {
	opacity: 1;
	transform: translateY(8px);
}

h3 {
	background: linear-gradient(
		145deg,
		rgba(18, 178, 255, 1) 0%,
		rgba(197, 58, 255, 1) 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 22px;
	margin: 20px 0px 7px 0px;
	text-shadow: var(--card-header-shadow);
}

img {
	width: 60px;
	height: 60px;
	margin: 14px 18px 14px 18px;
	transition: all 100ms cubic-bezier(0.47, 0, 0.2, 1);
}
</style>
