// Wire is a library for simplifying the handling of AJAX requests using the fetch api and ES8.
// Copyright Bitfluent LLC 2021.

// Define handleStatusCodes. Called after fetch request in main Wire methods.
function handleStatusCodes(response, errorCallback, scope) {
	// Returns JSON. Called at each stage if returnJSON is true.
	function validateJSON() {
		let data;
		try {
			data = response.json();
		} catch (err) {
			data = response.text();
		}
		return data;
	}

	if (response.ok) {
		if (scope.returnJSON) {
			return validateJSON();
		}
		return response;
	}

	// If status codes are not between 200-299:
	if (errorCallback !== undefined) {
		errorCallback(response.status);
		if (scope.returnJSON) {
			return validateJSON();
		}
		return response;
	}
	// If no error callback is specified:
	console.error(`Wire: HTTP error - ${response.status}`);
	if (scope.returnJSON) {
		return validateJSON();
	}
	return response;
}

// -=-=-=-=-=- Create class and export -=-=-=-=-=-
export default class Wire {
	constructor(options) {
		// Define instance properties based on options. Set default if none provided.
		this.apiRoot =
			options.apiRoot !== undefined && typeof options.apiRoot == 'string'
				? options.apiRoot
				: '';
		this.headers =
			options.headers !== undefined && typeof options.headers == 'object'
				? options.headers
				: { 'Content-Type': 'application/json; charset=UTF-8' };

		// For auth header. Accepts a string or function that returns a string.
		this.auth = () => {
			if (typeof options.auth == 'undefined') {
				return null;
			}

			if (typeof options.auth == 'function') {
				if (typeof options.auth() !== 'string') {
					console.error(
						'Wire: functions passed into the auth option must return a string. The returned result will be placed into the authorization header on each request.'
					);
					return;
				}
				return options.auth();
			}

			if (typeof options.auth == 'string') {
				return options.auth;
			}

			if (options.auth !== 'undefined') {
				console.error(
					'Wire: Authorization header value must be a string or a function that returns a string. Functions that return strings are called on each request (this is ideal when authorization tokens change dynamically).'
				);
				return;
			}

			return null;
		};

		// For sending cookies
		this.includeCredentials =
			options.includeCredentials !== undefined &&
			typeof options.includeCredentials == 'boolean'
				? options.includeCredentials
				: false;

		// For returning JSON automatically on response.
		this.returnJSON =
			options.returnJSON !== undefined && typeof options.returnJSON == 'boolean'
				? options.returnJSON
				: false;
	}

	// -=-=-=-=-=- Handler for GET requests -=-=-=-=-=-
	async get(url, errorCallback) {
		let newHeaders = this.headers;

		if (this.auth() !== null) {
			newHeaders['Authorization'] = this.auth();
		}

		let options = {
			method: 'GET',
			headers: this.headers,
			credentials: this.includeCredentials ? 'include' : 'same-origin',
		};

		let response = await fetch(this.apiRoot + url, options).catch((error) => {
			console.error(`Wire: fetch error: ${error}`);
			if (errorCallback !== undefined) errorCallback(error);
		});

		return handleStatusCodes(response, errorCallback, this);
	}

	// -=-=-=-=-=- Handler for POST requests -=-=-=-=-=-
	async post(url, body, errorCallback) {
		let options = {
			method: 'POST',
			credentials: this.includeCredentials ? 'include' : 'same-origin',
			headers: this.headers,
			body: typeof body == 'object' ? JSON.stringify(body) : body,
		};

		let response = await fetch(this.apiRoot + url, options).catch((error) => {
			if (errorCallback !== undefined) {
				errorCallback(error);
			} else {
				console.error(`Wire: fetch error: ${error}`);
			}
		});

		return handleStatusCodes(response, errorCallback, this);
	}

	// -=-=-=-=-=- Handler for PUT requests -=-=-=-=-=-
	async put(url, body, errorCallback) {
		let options = {
			method: 'PUT',
			credentials: this.includeCredentials ? 'include' : 'same-origin',
			headers: this.headers,
			body: typeof body == 'object' ? JSON.stringify(body) : body,
		};

		let response = await fetch(this.apiRoot + url, options).catch((error) => {
			console.error(`Wire: fetch error: ${error}`);
			if (errorCallback !== undefined) errorCallback(error);
		});

		return handleStatusCodes(response, errorCallback, this);
	}

	// -=-=-=-=-=- Handler for DELETE requests -=-=-=-=-=-
	async delete(url, errorCallback) {
		let options = {
			method: 'DELETE',
			credentials: this.includeCredentials ? 'include' : 'same-origin',
			headers: this.headers,
		};

		let response = await fetch(this.apiRoot + url, options).catch((error) => {
			console.error(`Wire: fetch error: ${error}`);
			if (errorCallback !== undefined) errorCallback(error);
		});

		return handleStatusCodes(response, errorCallback, this);
	}
}
