<template>
	<div class="stickyNav" :class="{ open: stickyNavIsOpen }">
		<NavGutsMobile v-if="isMobile" />
		<NavGutsDesktop v-else />
	</div>
</template>

<script>
// Dependency Import
import { computed } from 'vue';
import { mainStore } from '@/store/main';

// Component Import
import NavGutsDesktop from '@/components/navigation/NavGutsDesktop.vue';
import NavGutsMobile from '@/components/navigation/NavGutsMobile.vue';

export default {
	name: 'StickyNav',
	components: {
		NavGutsDesktop,
		NavGutsMobile,
	},

	setup() {
		// Injections

		// Setup Returns
		return {
			isMobile: computed(() => {
				return mainStore.getState().isMobile;
			}),
			stickyNavIsOpen: computed(() => {
				return mainStore.getState().stickyNavIsOpen;
			}),
		};
	},
};
</script>

<style scoped>
.stickyNav {
	width: 100%;
	height: 64px;
	border: solid green 0px;
	position: fixed;
	background: rgb(0, 234, 255);
	background: var(--hero-gradient);
	background-size: 200%;
	box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.3);
	animation: gradientMove 30s infinite;
	transition: all 450ms ease-in-out;
	transform: translateY(-90px);
	visibility: hidden;
	z-index: 15;
}

.open {
	visibility: visible;
	transform: translateY(0px);
}

@keyframes gradientMove {
	0% {
		background-position: 0%;
	}
	50% {
		background-position: 100%;
	}
	100% {
		background-position: 0%;
	}
}
</style>
