<template>
	<div id="mainCont">
		<span>
			<router-link class="links" :to="{ name: 'Login' }" @click="smoothScrollTop()"
				>Login</router-link
			>
			| Copyright &#169; Bitfluent LLC 2021
		</span>

		<div id="SMIcons">
			<a href="https://www.facebook.com/bitfluent" target="blank">
				<img src="@/assets/facebook.svg" />
			</a>
		</div>
	</div>
</template>

<script>
// Dependency Imports
import {} from 'vue';
import { smoothScrollTop } from '@/plugins/utilities';

//Component Imports

export default {
	name: 'Fototer',
	components: {},

	setup() {
		return {
			smoothScrollTop,
		};
	},
};
</script>

<style scoped>
#mainCont {
	min-height: 126px;
	border: solid yellow 0px;
	background-color: var(--body-bg-color);
	background: var(--footer-gradient);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap-reverse;
	color: var(--text-color);
	padding: 20px 60px 20px 60px;
	font-size: 14px;
}

#SMIcons {
	border: solid blue 0px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 20px 0px 20px 0px;
}

a {
	color: var(--text-color);
	text-decoration: none;
}

img {
	width: 34px;
	height: 40px;
	margin: 0px 10px 0px 10px;
}

.links {
	font-weight: bold;
	color: rgba(18, 178, 255, 1);
}
</style>
