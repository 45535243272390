import Wire from './wire.js';
// import { mainStore } from '@/store/main.js';

export let api = new Wire({
	apiRoot: `https://api.bitfluent.us`,
	headers: {
		'Content-Type': 'application/json; charset=UTF-8',
	},
	// auth: () => {
	// 	return `Bearer ${mainStore.getState().apiToken}`;
	// },
	includeCredentials: true,
	returnJSON: true,
});
