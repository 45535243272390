<template>
	<div @click="toggleTheme">
		<transition name="swap" mode="out-in">
			<img v-if="themeIsDark" src="@/assets/theme-moon-icon.svg" class="moon" />
			<img v-else src="@/assets/theme-sun-icon.svg" class="sun" />
		</transition>
	</div>
</template>

<script>
// Dependency Imports
import { computed } from 'vue';
import { mainStore } from '@/store/main';

//Component Imports

export default {
	name: 'ThemeToggleButton',
	components: {},

	setup() {
		return {
			themeIsDark: computed(() => {
				return mainStore.getState().themeIsDark;
			}),
			toggleTheme,
		};
	},
};

// Misc methods

function toggleTheme() {
	mainStore.setDarkMode(!mainStore.getState().themeIsDark);
}
</script>

<style scoped>
div {
	width: 28px;
	height: 28px;
	margin: 0px 40px 0px 40px;
}

img.moon {
	border: solid yellow 0px;
	width: 26px;
}

img.sun {
	border: solid yellow 0px;
	width: 28px;
}

.swap-enter-active,
.swap-leave-active {
	transition: all 300ms cubic-bezier(0.47, 0, 0.2, 1);
}

.swap-enter-from,
.swap-leave-to {
	transform: rotateZ(180deg) scale(0);
}
</style>
