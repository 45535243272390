<template>
	<div class="roundedCard">
		<slot></slot>
	</div>
</template>

<script>
// Dependency Imports
import {} from 'vue';

//Component Imports

export default {
	name: 'FadeCard',
	components: {},

	setup() {
		return {};
	},
};
</script>

<style scoped>
div.roundedCard {
	width: 100%;
	height: 100%;
	padding: 20px;
	border-radius: 40px;
	background: rgba(255, 255, 255, 0.5);
	background: var(--card-gradient);
	box-shadow: var(--card-shadow);
}
</style>
