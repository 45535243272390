<template>
	<div class="navGuts">
		<router-link
			:to="{ name: 'HomePage' }"
			@click="smoothScrollTop()"
			class="header-side-element"
		>
			<img src="@/assets/headerLogo.svg" class="logo"
		/></router-link>
		<HamburgerButton class="header-side-element" />
	</div>
</template>

<script>
// Dependencies
import { computed } from 'vue';
import { mainStore } from '@/store/main';
import { smoothScrollTop } from '@/plugins/utilities';

// Component Imports
import HamburgerButton from '@/components/clickables/HamburgerButton.vue';

export default {
	name: 'NavGutsMobile',
	components: {
		HamburgerButton,
	},

	setup() {
		// Injections

		// Setup Returns
		return {
			mainStore,
			navIsOpen,
			smoothScrollTop,
		};
	},
};

const navIsOpen = computed(() => {
	return mainStore.getState().navIsOpen;
});
</script>

<style scoped>
div.navGuts {
	width: 100%;
	height: 100%;
	border: solid green 0px;
	display: flex;
	position: relative;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	z-index: 5;
}

img.logo {
	width: 100px;
	height: auto;
	transform: translateY(2px);
}

#hamburgerCont {
	border: solid green 0px;
	width: 26px;
	height: 19px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.header-side-element {
	margin: 0px 22px 0px 22px;
}

div.patty {
	width: 100%;
	height: 3px;
	border-radius: 0px 3px 0px 3px;
	background-color: rgba(255, 255, 255, 0.9);
}
</style>
