<template>
	<div class="navGuts">
		<router-link
			:to="{ name: 'HomePage' }"
			@click="smoothScrollTop()"
			class="header-side-element"
		>
			<img src="@/assets/headerLogo.svg" class="logo" />
		</router-link>
		<div class="navLinksCont" @click="smoothScrollTop()">
			<router-link class="mainLink" :to="{ name: 'HomePage' }">Home</router-link>
			<router-link class="mainLink" :to="{ name: 'About' }">About</router-link>
			<router-link class="mainLink" :to="{ name: 'Portfolio' }"
				>Portfolio</router-link
			>
			<router-link class="mainLink" :to="{ name: 'Contact' }">Contact</router-link>
		</div>
		<ThemeToggleButton />
	</div>
</template>

<script>
import ThemeToggleButton from '@/components/clickables/ThemeToggleButton.vue';

import {} from 'vue';
import { smoothScrollTop } from '@/plugins/utilities';

export default {
	name: 'NavGutsDesktop',
	components: {
		ThemeToggleButton,
	},

	setup() {
		// Injections

		// Setup Returns
		return {
			smoothScrollTop,
		};
	},
};
</script>

<style scoped>
div.navGuts {
	width: 100%;
	height: 100%;
	border: solid green 0px;
	display: flex;
	position: relative;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	z-index: 10;
}

div.navLinksCont {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 80%;
	max-width: 430px;
}

a.mainLink {
	font-size: 17px;
	color: rgb(255, 255, 255);
	text-decoration: none;
	width: 90px;
	border: solid red 0px;
	text-align: center;
	opacity: 0.75;
	transition-timing-function: cubic-bezier(0.74, -0.11, 0.48, 1.23);
	transition: font-weight 150ms 0ms, opacity 300ms 0ms;
}

.signIn {
	width: 100px;
	height: 30px;
	border-radius: 20px;
	font-size: 14px;
	font-weight: bold;
	color: rgb(255, 255, 255);
	text-decoration: none;
	background-color: rgba(255, 255, 255, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
}

.signInArrow {
	width: 9px;
	margin: 1px 0px 0px 8px;
}

a:hover {
	opacity: 1;
}

a.router-link-active {
	font-weight: bold;
	opacity: 1;
}

img.logo {
	width: 100px;
	height: auto;
	transform: translateY(1px);
}

.header-side-element {
	margin: 0px 0px 0px 34px;
}

@media only screen and (max-width: 800px) {
	div.navLinksCont {
		flex-direction: column;
	}
}
</style>
