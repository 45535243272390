<template>
	<div id="hamburgerCont" @click="mainStore.toggleNav()">
		<div class="patty" :class="{ openNav: navIsOpen }"></div>
		<div class="patty" :class="{ openNav: navIsOpen }"></div>
		<div class="patty" :class="{ openNav: navIsOpen }"></div>
	</div>
</template>

<script>
import { mainStore } from '@/store/main';
import { computed } from 'vue';

export default {
	name: 'HamburgerButton',
	components: {},

	setup() {
		return {
			mainStore,
			navIsOpen: computed(() => {
				return mainStore.getState().navIsOpen;
			}),
		};
	},
};
</script>

<style scoped>
#hamburgerCont {
	border: solid green 0px;
	width: 26px;
	height: 19px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

div.patty {
	width: 100%;
	height: 3px;
	border-radius: 0px 3px 0px 3px;
	background-color: rgba(255, 255, 255, 0.9);
	transition: cubic-bezier(0.5, -0.5, 0.5, 1.5) 300ms;
}

.openNav:nth-child(1) {
	transform: translateY(7px);
}

.openNav:nth-child(2) {
	background-color: black;
}

.openNav:nth-child(3) {
	transform: translateY(-8px);
}
</style>
